var app;

angular.isUndefinedOrNull = function(val) {
  return angular.isUndefined(val) || val === null;
};

angular.isDefinedOrNotNull = function(val) {
  return angular.isDefined(val) || val !== null;
};

app = angular.module('userApp', ['ngMaterial', 'ngMdIcons', 'ckeditor', 'flow', 'ui.ace'], function($interpolateProvider) {
  $interpolateProvider.startSymbol('<%');
  $interpolateProvider.endSymbol('%>');
}).config([
  'flowFactoryProvider', function(flowFactoryProvider) {
    flowFactoryProvider.defaults = {
      target: '/uploadImages/',
      singleFile: true,
      permanentErrors: [500, 501],
      maxChunkRetries: 1,
      chunkRetryInterval: 5000,
      simultaneousUploads: 1
    };
    flowFactoryProvider.on('catchAll', function(event) {
      console.log(event);
    });
    flowFactoryProvider.factory = fustyFlowFactory;
  }
]).config(function($mdThemingProvider) {
  $mdThemingProvider.theme('docs-dark', 'default').primaryPalette('light-blue').dark();
});

app.controller('ParentController', function($scope) {
  $scope.messages = [];
  $scope.$on('from-iframe', function(e, message) {
    console.log('1');
    $scope.messages.push(message);
  });
  $scope.message = function() {
    $scope.$broadcast('from-parent', 'Sent from parent');
  };
});

app.controller('userController', function($scope, $http, $interval, $mdDialog, $mdToast, $document) {
  $scope.users = {};
  $scope.loading = false;
  $scope.init = function() {
    $scope.loading = true;
    $http.get('/api/users').success(function(data) {
      $scope.users = data.users;
      $scope.roles = {
        repeatSelect: null,
        availableOptions: data.roles
      };
      $scope.loading = false;
    });
  };
  $scope.openAddUserDialog = function(tip, user) {
    var category;
    if (typeof category !== "undefined" && category !== null) {
      category = category;
    }
    $mdDialog.show({
      templateUrl: 'dialogadduser',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        roles: $scope.roles,
        user: user
      },
      controller: [
        '$scope', 'roles', 'user', function($scope, roles, user) {
          $scope.showConfirmToast = function(content) {
            $mdToast.show($mdToast.simple().content(content).position('top right').hideDelay(3000));
          };
          $scope.loading = false;
          $scope.showActionToast = function(data) {
            return $mdToast.show({
              controller: [
                '$scope', '$mdToast', function($scope, $mdToast) {
                  $scope.closeToast = function() {
                    $mdToast.hide();
                  };
                  $scope.errors = data;
                }
              ],
              templateUrl: 'toastadduser',
              parent: $document[0].querySelector('#mdDialog'),
              hideDelay: 0,
              position: 'top right'
            });
          };
          if (tip === 1) {
            $scope.loading = true;
            $scope.errors = null;
            $http.get('api/users/' + user.id).success(function(data) {
              $scope.user = data.users;
              $scope.role_user = data.role;
              $scope.roles = {
                repeatSelect: null,
                availableOptions: data.roles
              };
              $('.password').hide();
              $('#addUser').hide();
              $('#editUser').show();
              $('#editUser').attr('data-id', user.id);
              $('#formTitle').html('Editare ' + $scope.user.first_name + ' ' + $scope.user.last_name);
              $scope.loading = false;
            });
          }
          $scope.roles = roles;
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
          $scope.addUser = function() {
            $scope.loading = true;
            $scope.errors = null;
            $http.post('/api/users', {
              first_name: $scope.user.first_name,
              last_name: $scope.user.last_name,
              email: $scope.user.email,
              password: $scope.user.password,
              confirm_password: $scope.user.confirm_password,
              role: $scope.role,
              status: 1
            }).success(function() {
              $mdDialog.hide();
              $scope.showConfirmToast('Utilizatorul a fost aduagat cu success !');
            }).error(function(data) {
              $scope.loading = false;
              $scope.showActionToast(data);
            });
          };
          return $scope.editUser = function() {
            var id;
            $scope.errors = null;
            $scope.loading = true;
            id = $('#editUser').attr('data-id');
            return $http.put('/api/users/' + id, {
              id: id,
              first_name: $scope.user.first_name,
              last_name: $scope.user.last_name,
              email: $scope.user.email,
              role: $scope.role
            }).success(function() {
              $scope.showConfirmToast('Utilizatorul a fost editat cu success !');
              $mdDialog.hide();
              $scope.loading = false;
              $scope.errors = null;
            }).error(function(data) {
              $scope.loading = false;
              $scope.showActionToast(data);
            });
          };
        }
      ]
    }).then(function() {
      $scope.init();
    }, function() {});
  };
  $scope.status = function(user, status) {
    $scope.loading = true;
    return $http.put('/api/enable_user/' + user.id, {
      status: status,
      id: user.id
    }).success(function(data) {
      $scope.users = data.users;
      $scope.roles = {
        repeatSelect: null,
        availableOptions: data.roles
      };
      $scope.loading = false;
    });
  };
  $scope.init();
  $interval(function() {
    $scope.init();
  }, 60000);
  $scope.loadRoles = function(role) {
    return $http.get('/api/roles').success(function(data) {
      $scope.role = role;
      $scope.roles = {
        repeatSelect: null,
        availableOptions: data.roles
      };
      $scope.loading = false;
    });
  };
});

app.controller('courseDataController', function($scope, $http, $interval, $mdDialog, $mdToast, $document) {
  $scope.listContent = {};
  $scope.parents = {};
  $scope.loading = false;
  $scope.apiDestination = '';
  $scope.dataName = '';
  $scope.dialogTitle = '';
  $scope.editorOptions = {
    language: 'en',
    allowedContent: true,
    entities: false
  };
  $scope.init = function(apiDestination, dataName) {
    apiDestination = apiDestination ? apiDestination : '/api/coursecategory';
    $scope.loading = true;
    $scope.apiDestination = apiDestination;
    $scope.dataName = dataName;
    $http.get(apiDestination).success(function(data) {
      $scope.listContent = data.list;
      $scope.parents = {
        repeatSelect: null,
        availableOptions: data.parents
      };
      $scope.loading = false;
    });
  };
  $scope.openCourseDataDialog = function(type, dataType, category) {
    if (category != null) {
      category = category;
    }
    $mdDialog.show({
      templateUrl: 'dialogcoursedata',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true,
      locals: {
        category: category,
        dataType: dataType
      },
      controller: [
        'category', function(category) {
          $scope.showConfirmToast = function(content) {
            $mdToast.show($mdToast.simple().content(content).position('top right').hideDelay(3000));
          };
          $scope.showActionToast = function(data) {
            $mdToast.show({
              controller: [
                '$scope', '$mdToast', function($scope, $mdToast) {
                  $scope.closeToast = function() {
                    $mdToast.hide();
                  };
                  $scope.errors = data;
                }
              ],
              templateUrl: 'toastaddCategory',
              parent: $document[0].querySelector('#mdDialog'),
              hideDelay: 0,
              position: 'top right'
            });
          };
          $scope.obj = {};
          if (type === 1) {
            $scope.loading = true;
            $scope.errors = null;
            switch (dataType) {
              case 1:
                $scope.hideSelect = true;
                $scope.hidePaid = true;
                break;
              case 2:
                $scope.hideTag = true;
                $scope.hidePaid = true;
                $scope.hideBadge = true;
                $scope.hideBackdrop = true;
                break;
              case 3:
                $scope.hidePaid = true;
                $scope.hideTag = true;
            }
            $http.get($scope.apiDestination + '/' + category.id).success(function(data) {
              $scope.category = data.item;
              $('#addDataButton').hide();
              $('#editDataButton').show();
              $('#editDataButton').attr('data-id', category.id);
              $scope.dialogTitle = 'Editare ' + $scope.dataName + ' : ' + $scope.category.name;
              $scope.loading = false;
            });
          } else {
            $scope.dialogTitle = 'Adauga ' + $scope.dataName + '';
            $scope.category = {};
            switch (dataType) {
              case 1:
                $scope.hideSelect = true;
                $scope.hidePaid = true;
                break;
              case 2:
                $scope.hideTag = true;
                $scope.hidePaid = true;
                $scope.hideBackdrop = true;
                $scope.hideBadge = true;
                break;
              case 3:
                $scope.hideTag = true;
            }
          }
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
          $scope.addData = function() {
            var fd;
            $scope.errors = null;
            fd = new FormData();
            fd.append('id', null);
            fd.append('name', $scope.category.name);
            fd.append('tag', $scope.category.tag);
            fd.append('description', $scope.category.description);
            fd.append('backdrop_color', $scope.category.backdrop_color);
            fd.append('parent_id', $scope.category.parent_id);
            if ($scope.category.paid) {
              fd.append('paid', $scope.category.paid);
            }
            if ($scope.obj.backdrop_image.files.length > 0) {
              fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file);
            }
            if ($scope.obj.badge_image.files.length > 0) {
              fd.append('badge_image_file', $scope.obj.badge_image.files[0].file);
            }
            $http.post($scope.apiDestination, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': void 0
              }
            }).success(function() {
              $mdDialog.hide();
            }).error(function(data) {
              $scope.errors = data.errors;
              $scope.loading = false;
            });
          };
          $scope.editData = function() {
            var fd, ref;
            console.log($scope.category);
            $scope.errors = null;
            $scope.loading = true;
            $scope.errors = null;
            fd = new FormData();
            fd.append('id', $scope.category.id);
            fd.append('name', $scope.category.name);
            fd.append('tag', $scope.category.tag);
            fd.append('description', $scope.category.description);
            fd.append('backdrop_color', $scope.category.backdrop_color);
            fd.append('course_group_id', $scope.category.course_group_id);
            if ($scope.category.paid) {
              fd.append('paid', (ref = $scope.category.paid) != null ? ref : {
                1: 0
              });
            }
            if ($scope.obj.backdrop_image.files.length > 0) {
              fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file);
            }
            if ($scope.obj.badge_image.files.length > 0) {
              fd.append('badge_image_file', $scope.obj.badge_image.files[0].file);
            }
            return $http.post($scope.apiDestination, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': void 0
              }
            }).success(function() {
              $mdDialog.hide();
              $scope.loading = false;
              $scope.errors = null;
            }).error(function(data) {
              $scope.errors = data;
              $scope.loading = false;
            });
          };
        }
      ]
    }).then(function() {
      $scope.init($scope.apiDestination, $scope.dataName);
    });
  };
  $scope.init($scope.apiDestination, $scope.dataName);
  $interval(function() {
    $scope.init($scope.apiDestination, $scope.dataName);
  }, 60000);
});

app.controller('lessonController', function($scope, $http, $interval, $mdDialog, $mdToast, $document) {
  $scope.lesson = {};
  $scope.lesson.dataType = 0;
  $scope.editorOptions = {
    language: 'ro',
    allowedContent: true,
    entities: false
  };
  $scope.init = function() {
    $http.get('/api/lessons').success(function(data) {
      $scope.listContent = data.list;
      $scope.parents = {
        repeatSelect: null,
        availableOptions: data.parents
      };
      $scope.loading = false;
    });
  };
  $scope.openLessonDialog = function(type, lesson) {
    if (angular.isDefined(lesson)) {
      lesson = lesson;
    }
    console.log($scope.lesson);
    $mdDialog.show({
      templateUrl: 'dialogLesson',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true,
      locals: {
        lesson: lesson
      },
      controller: [
        'lesson', function(lesson) {
          $scope.showConfirmToast = function(content) {
            $mdToast.show($mdToast.simple().content(content).position('top right').hideDelay(3000));
          };
          $scope.showActionToast = function(data) {
            $mdToast.show({
              controller: [
                '$scope', '$mdToast', function($scope, $mdToast) {
                  $scope.closeToast = function() {
                    $mdToast.hide();
                  };
                  $scope.errors = data;
                }
              ],
              templateUrl: 'toastaddCategory',
              parent: $document[0].querySelector('#mdDialog'),
              hideDelay: 0,
              position: 'top right'
            });
          };
          $scope.obj = {};
          $scope.obj.video = null;
          $scope.obj.badge_image = null;
          $scope.obj.backdrop_image = null;
          if (type === 1) {
            $scope.loading = true;
            $scope.errors = null;
            $http.get('/api/lessons/' + lesson.id).success(function(data) {
              $scope.lesson = data.item;
              $('#addLessonButton').hide();
              $('#editLessonButton').show();
              $('#editLessonButton').attr('data-id', lesson.id);
              $('#formTitle').html('Editare lectie : ' + $scope.lesson.name);
              if ($scope.lesson.video) {
                $scope.lesson.dataType = 0;
              }
              if ($scope.lesson.quiz) {
                $scope.lesson.dataType = 1;
              }
              if ($scope.lesson.compiler) {
                $scope.lesson.dataType = 2;
              }
              if (angular.isUndefinedOrNull($scope.lesson.compilerItems) || $scope.lesson.compilerItems === "" || typeof $scope.lesson.compilerItems !== 'object') {
                console.log($scope.lesson);
              } else {
                console.log($scope.lesson);
                $scope.lesson.compilerItems = JSON.parse($scope.lesson.compilerItems);
                console.log($scope.lesson);
              }
              if (angular.isUndefinedOrNull($scope.lesson.quizItems) || $scope.lesson.quizItems === "" || typeof $scope.lesson.quizItems !== 'object') {
                console.log($scope.lesson);
              } else {
                console.log($scope.lesson);
                $scope.lesson.quizItems = JSON.parse($scope.lesson.quizItems);
                console.log($scope.lesson);
              }
              $scope.loading = false;
            });
          } else {
            $scope.lesson = null;
          }
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
          $scope.addCompilerItem = function() {
            $scope.lesson.compilerItems[Object.keys($scope.lesson.compilerItems).length] = {
              'objective': '',
              'tag': '',
              'tag_content': '',
              'points_value': ''
            };
          };
          $scope.removeCompilerItem = function(index) {
            $scope.lesson.compilerItems.splice(index, 1);
          };
          $scope.addQuizItem = function() {
            $scope.lesson.quizItems[Object.keys($scope.lesson.quizItems).length] = {
              'example': '',
              'question': '',
              'answer': '',
              'points_value': ''
            };
          };
          $scope.removeQuizItem = function(index) {
            $scope.lesson.quizItems.splice(index, 1);
          };
          $scope.addData = function() {
            var fd, xhr;
            $scope.errors = null;
            fd = new FormData();
            fd.append('_token', $('input[name="_token"]').val());
            fd.append('id', $scope.lesson.id);
            fd.append('name', $scope.lesson.name);
            fd.append('order', $scope.lesson.order);
            fd.append('description', $scope.lesson.description);
            fd.append('content', $scope.lesson.content);
            fd.append('backdrop_color', $scope.lesson.backdrop_color);
            fd.append('course_id', $scope.lesson.course_id);
            if ($scope.lesson.dataType === "0") {
              if ($scope.obj.video.files.length > 0) {
                fd.append('video', $scope.obj.video.files[0].file);
              }
            }
            if ($scope.lesson.dataType === "1") {
              fd.append('quiz', 1);
              fd.append('compiler', 0);
              fd.append('quizItems', JSON.stringify($scope.lesson.quizItems));
            }
            if ($scope.lesson.dataType === "2") {
              fd.append('compiler', 1);
              fd.append('quiz', 0);
              fd.append('compilerItems', JSON.stringify($scope.lesson.compilerItems));
            }
            if ($scope.obj.suport_lectie.files.length > 0) {
              console.log($scope.obj.suport_lectie);
              fd.append('suport_lectie', $scope.obj.suport_lectie.files[0].file);
            }
            if ($scope.obj.backdrop_image.files.length > 0) {
              fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file);
            }
            if ($scope.obj.badge_image.files.length > 0) {
              fd.append('badge_image_file', $scope.obj.badge_image.files[0].file);
            }
            xhr = new XMLHttpRequest;
            xhr.upload.onprogress = function(e) {
              $scope.$apply(function() {
                var percentCompleted;
                percentCompleted = void 0;
                if (e.lengthComputable) {
                  percentCompleted = Math.round(e.loaded / e.total * 100);
                  if (percentCompleted < 1) {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = 'Uploading...';
                    }
                  } else if (percentCompleted === 100) {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = 'Saving...';
                    }
                  } else {
                    $scope.progress = percentCompleted;
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = percentCompleted + '%';
                    }
                  }
                }
              });
            };
            xhr.upload.onload = function(e) {
              $mdDialog.hide();
              $scope.$apply(function() {
                if (angular.isDefinedOrNotNull($scope.obj.video)) {
                  $scope.obj.video.files[0].uploadStatus = 'Uploaded!';
                }
                setTimeout((function() {
                  $scope.$apply(function() {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = '';
                    }
                    $scope.loading = false;
                  });
                }), 4000);
              });
            };
            xhr.open('POST', '/api/lessons');
            xhr.send(fd);
          };
          $scope.removeData = function() {};
          $scope.editData = function() {
            var fd, xhr;
            $scope.errors = null;
            $scope.loading = true;
            $scope.errors = null;
            fd = new FormData();
            fd.append('_token', $('input[name="_token"]').val());
            fd.append('id', $scope.lesson.id);
            fd.append('name', $scope.lesson.name);
            fd.append('order', $scope.lesson.order);
            fd.append('description', $scope.lesson.description);
            fd.append('content', $scope.lesson.content);
            fd.append('backdrop_color', $scope.lesson.backdrop_color);
            fd.append('course_id', $scope.lesson.course_id);
            if ($scope.lesson.dataType === "0") {
              if ($scope.obj.video !== void 0) {
                if ($scope.obj.video.files.length > 0) {
                  fd.append('video', $scope.obj.video.files[0].file);
                }
              }
            }
            if ($scope.lesson.dataType === "1") {
              fd.append('quiz', 1);
              fd.append('compiler', 0);
              fd.append('quizItems', JSON.stringify($scope.lesson.quizItems));
            }
            if ($scope.lesson.dataType === "2") {
              fd.append('compiler', 1);
              fd.append('quiz', 0);
              fd.append('compilerItems', JSON.stringify($scope.lesson.compilerItems));
            }
            if ($scope.obj.suport_lectie.files.length > 0) {
              console.log($scope.obj.suport_lectie);
              fd.append('suport_lectie', $scope.obj.suport_lectie.files[0].file);
            }
            if ($scope.obj.backdrop_image.files.length > 0) {
              fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file);
            }
            if ($scope.obj.badge_image.files.length > 0) {
              fd.append('badge_image_file', $scope.obj.badge_image.files[0].file);
            }
            xhr = new XMLHttpRequest;
            xhr.upload.onprogress = function(e) {
              $scope.$apply(function() {
                var percentCompleted;
                percentCompleted = void 0;
                if (e.lengthComputable) {
                  percentCompleted = Math.round(e.loaded / e.total * 100);
                  if (percentCompleted < 1) {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = 'Uploading...';
                    }
                  } else if (percentCompleted === 100) {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = 'Saving...';
                    }
                  } else {
                    $scope.progress = percentCompleted;
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = percentCompleted + '%';
                    }
                  }
                }
              });
            };
            xhr.upload.onload = function(e) {
              $mdDialog.hide();
              $scope.$apply(function() {
                if (angular.isDefinedOrNotNull($scope.obj.video)) {
                  $scope.obj.video.files[0].uploadStatus = 'Uploaded!';
                }
                setTimeout((function() {
                  $scope.$apply(function() {
                    if (angular.isDefinedOrNotNull($scope.obj.video)) {
                      $scope.obj.video.files[0].uploadStatus = '';
                    }
                    $scope.loading = false;
                  });
                }), 4000);
              });
            };
            xhr.open('POST', '/api/lessons');
            return xhr.send(fd);
          };
        }
      ]
    }).then(function() {
      $scope.init();
    });
  };
  $scope.init();
  $interval(function() {
    $scope.init();
  }, 60000);
});

app.controller('homeController', function($scope, $sce, $http) {
  $scope.messages = [];
  $scope.objectives = "";
  $scope.url = $sce.trustAsResourceUrl('/iframe');
  $http.get('/html').success(function(response) {
    $scope.objectives = response.objectives;
    $scope.validator(response.html);
  });
  $scope.$on('from-iframe', function(e, message) {
    var key, status;
    key = message.key;
    status = message.status;
    $scope.objectives[key]['status'] = status;
  });
  $scope.validator = function(data) {
    $scope.$broadcast('validator', data);
  };
  $scope.message = function(data) {
    $scope.$broadcast('from-parent', data);
  };
  $scope.code = "ceva  altceva  list  item1";
  $scope.aceLoaded = function(_editor) {
    var _renderer, _session;
    _session = _editor.getSession();
    _renderer = _editor.renderer;
    _editor.$blockScrolling = Infinity;
    _editor.setReadOnly(false);
    _session.setUndoManager(new ace.UndoManager());
    _renderer.setShowGutter(true);
    _editor.on("changeSession", function(obj) {});
    _session.on("change", function() {
      $scope.message(_editor.getSession().getValue());
    });
  };
});

app.controller('userNavController', function($scope, $http, $interval, $mdDialog, $window) {
  $scope.loading = false;
  $scope.user = {};
  $scope.recovery = false;
  $scope.init = function() {};
  $scope.init();
  $scope.isOpen = false;
  $scope.demo = {
    isOpen: false,
    count: 0,
    selectedDirection: 'left'
  };
  $scope.logout = function() {
    window.location.replace('/logout');
  };
  $scope.loginDialogSubmit = function() {
    $scope.loading = true;
    $http.post('login', $scope.user).success(function(data) {
      $scope.user = data.user;
      if (data.redirect) {
        window.location.replace(data.redirect);
      } else if (data.errors) {
        console.log(data.errors);
        $scope.errors = data.errors;
        $scope.refillData = data.inputs;
        $scope.loading = false;
      }
    });
  };
  $scope.registerDialogSubmit = function() {
    $scope.loading = true;
    $http.post('register', $scope.user).success(function(data) {
      var confirm;
      console.log(data);
      if (data.success === true) {
        $scope.errors = data.errors;
        confirm = $mdDialog.confirm().title('Contul a fost creeat cu succes').content('Contul tau a fost creeat. Te rugam sa confirmi adresa de email pentru a te autentifica').ariaLabel('Lucky day').ok('Am confirmat').cancel('Confirm mai tarziu');
        $mdDialog.cancel();
        $mdDialog.show(confirm).then(function() {
          $scope.openLoginDialog();
          return;
          return function() {
            $mdDialog.cancel();
          };
        });
        $scope.notice = true;
      } else {
        $scope.errors = data.errors;
        $scope.loading = false;
      }
    });
  };
  $scope.cancel = function() {
    $mdDialog.cancel();
  };
  $scope.resendConfirmationMail = function() {
    $scope.loading = true;
    $http.post('retrimite_verificare', {
      'email': $scope.user.email
    }).success(function(data) {
      var confirm;
      console.log(data);
      if (data.accexist === true) {
        confirm = $mdDialog.confirm().title('Mail confirmare cont').content(data.message).ariaLabel('Lucky day');
        if (data.delivery === true) {
          confirm.ok('Confirm acum').cancel('Confirm mai tarziu');
        } else {
          confirm.ok('Autentificare').cancel('Inchide');
        }
        $mdDialog.show(confirm).then(function() {
          $scope.loading = false;
          $mdDialog.cancel();
          if (data.delivery === true) {
            window.open(data.mailclient, '_blank');
          } else {
            $scope.openLoginDialog();
          }
          return;
          return function() {
            $mdDialog.cancel();
            if (data.delivery === false) {
              $scope.openRegisterDialog();
            }
          };
        });
        $scope.notice = true;
      } else {
        confirm = $mdDialog.confirm().title('Mail confirmare cont').content(data.message).ariaLabel('Lucky day').ok('Creeaza cont').cancel('Inchide');
        $mdDialog.show(confirm).then(function() {
          $scope.loading = false;
          $mdDialog.cancel();
          $scope.openLoginDialog();
          return;
          return function() {
            $mdDialog.cancel();
          };
        });
        $scope.notice = true;
      }
    });
  };
  $scope.openRegisterDialog = function() {
    $mdDialog.show({
      templateUrl: 'registerdialog',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true
    }).then(function() {
      $scope.init();
    });
  };
  $scope.openRegisterDialog2 = function() {
    $mdDialog.cancel();
    $scope.openRegisterDialog();
  };
  $scope.passwordRecovery = function() {
    $scope.recovery = true;
  };
  $scope.passwordRecoverySend = function() {
    $http.post('reseteaza-parola', {
      email: $scope.user.email
    }).success(function(data) {
      var confirm;
      console.log(data);
      if (data.accexist === true) {
        confirm = $mdDialog.confirm().title('Mail resetare parola').content(data.message).ariaLabel('Lucky day');
        if (data.delivery === true) {
          confirm.ok('Reseteaza acum').cancel('Resetez mai tarziu');
        } else {
          confirm.ok('Reincearca').cancel('Inchide');
        }
        $mdDialog.show(confirm).then(function() {
          $scope.loading = false;
          $mdDialog.cancel();
          if (data.delivery === true) {
            window.open(data.mailclient, '_blank');
          } else {
            $scope.openLoginDialog();
          }
          return;
          return function() {
            $mdDialog.cancel();
            if (data.delivery === false) {
              $scope.openRegisterDialog();
            }
          };
        });
        $scope.notice = true;
      } else {
        confirm = $mdDialog.confirm().title('Mail resetare parola').content(data.message).ariaLabel('Lucky day').ok('Creeaza cont').cancel('Inchide');
        $mdDialog.show(confirm).then(function() {
          $scope.loading = false;
          $mdDialog.cancel();
          $scope.openLoginDialog();
          $scope.recovery = true;
          return;
          return function() {
            $mdDialog.cancel();
          };
        });
        $scope.notice = true;
      }
    });
  };
  $scope.openLoginDialog = function() {
    $scope.recovery = false;
    $scope.loading = false;
    $scope.errors = null;
    $mdDialog.show({
      templateUrl: 'logindialog',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true
    }).then(function() {
      $scope.init();
    });
  };
});

app.controller('SidenavController', function($scope, $http, $mdSidenav) {
  $scope.buildToggler = function(navID) {
    return function() {
      $mdSidenav(navID).toggle();
    };
  };
  $scope.toggleRight = $scope.buildToggler('left');
  $scope.close = function() {
    $mdSidenav('left').close();
  };
});

app.controller('quizController', function($scope, $http) {
  $scope.answers = {};
  $scope.canSendFullQuiz = 0;
  $scope.showAnswer = function(lessonId, questionId) {
    $http.post('/lessonQuizAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'getQA': true
    }).success(function(data) {
      $scope.answers[questionId] = {
        'asked': true,
        'value': data.answer,
        'submitted': true
      };
      $("input[name='response_" + questionId + "']").parent().find("label").html('');
      $("input[name='response_" + questionId + "']").val(data.answer);
      $("input[name='response_" + questionId + "']").css('color', 'black');
    });
  };
  $scope.sendAnswer = function(lessonId, questionId) {
    $http.post('/lessonQuizAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'userAnswer': $("input[name='response_" + questionId + "']").val()
    }).success(function(data) {
      $("input[name='response_" + questionId + "']").css('border-bottom-color', 'rgba(0,0,0,0.12)');
      if (angular.isDefined($scope.answers[questionId]['asked'])) {
        $scope.answers[questionId]['value'] = data.answer;
        $scope.answers[questionId]['submitted'] = true;
      } else {
        $scope.answers[questionId] = {
          'asked': false,
          'value': data.answer,
          'submitted': true
        };
      }
    });
  };
  $scope.sendFullQuiz = function(lessonId, questionsCount) {
    console.log(Object.keys($scope.answers).length);
    console.log($scope.answers);
    if (Object.keys($scope.answers).length === 0) {
      $scope.canSendFullQuiz = 666;
      $(".responseInput").css('border-bottom-color', 'red');
    }
    angular.forEach($scope.answers, function(v, k) {
      console.log(k);
      if (v.value !== null) {
        $scope.sendAnswer(lessonId, k);
      } else {
        $scope.canSendFullQuiz += 1;
        $(".responseInput").css('border-bottom-color', 'red');
        $("input[name='response_" + k + "']").css('border-bottom-color', 'red');
      }
    });
    if (Object.keys($scope.answers).length === questionsCount) {
      $scope.canSendFullQuiz = 0;
    }
    if ($scope.canSendFullQuiz === 0) {
      console.log($scope.answers);
      console.log(JSON.stringify($scope.answers));
      $http.post('/lessonQuizValidation', {
        'lessonid': lessonId,
        'quizResults': JSON.stringify($scope.answers)
      }).success(function(data) {
        if (data.success === true) {
          console.log('sent and succesfull');
        } else {
          console.log('sent and failed');
        }
      });
    } else {
      return angular.forEach($scope.answers, function(v, k) {
        if (v.value === null) {
          $("input[name='response_" + k + "']").css('border-color', 'red');
        }
      });
    }
  };
});

app.controller('compilerController', function($scope, $http) {
  $scope.answerAsked = {};
  $scope.answers = {};
  $scope.requestAnswer = function(lessonId, questionId) {
    $http.post('/compilerAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'getQA': true
    }).success(function(data) {
      $scope.answerAsked[questionId] = true;
      $scope.answers[questionId] = {
        'asked': true,
        'value': data.answer,
        'submitted': true
      };
      $("input[name='response_" + questionId + "']").parent().find("label").html('');
      $("input[name='response_" + questionId + "']").val(data.answer);
      $("input[name='response_" + questionId + "']").css('color', 'black');
    });
  };
  $scope.sendAnswer = function(lessonId, questionId) {
    return $http.post('/compilerAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'userAnswer': $("input[name='response_" + questionId + "']").val()
    }).success(function(data) {
      if (angular.isDefined($scope.answers[questionId]['asked'])) {
        $scope.answers[questionId]['value'] = data.answer;
        $scope.answers[questionId]['submitted'] = true;
      } else {
        $scope.answers[questionId] = {
          'asked': false,
          'value': data.answer,
          'submitted': true
        };
      }
    });
  };
});

app.controller('userPassReset', function($scope, $http) {
  $scope.userFields = {};
  $scope.responseMsg = null;
  $scope.updatePass = function() {
    $http.post('parola-noua', $scope.userFields).success(function(data) {
      return $scope.responseMsg = data.message;
    });
  };
  $scope.updateRequest = function() {
    $http.post('reseteaza-parola', {
      email: $scope.userFields.email
    }).success(function(data) {
      return $scope.responseMsg = data.message;
    });
  };
});

app.controller('userSettings', function($scope, $http) {
  $scope.userData = {};
  $scope.message = null;
  $scope.status = null;
  $scope.saveUserdata = function() {
    $http.post('updateData', $scope.userData).success(function(data) {
      $scope.message = data.msg;
      $scope.status = data.status;
    });
  };
});
