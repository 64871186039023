angular.isUndefinedOrNull = (val) ->
  return angular.isUndefined(val) || val == null

angular.isDefinedOrNotNull = (val) ->
  return angular.isDefined(val) || val != null

app = angular.module('userApp', ['ngMaterial', 'ngMdIcons', 'ckeditor', 'flow', 'ui.ace'], ($interpolateProvider) ->
  $interpolateProvider.startSymbol('<%')
  $interpolateProvider.endSymbol('%>')
  return
)
.config(['flowFactoryProvider', (flowFactoryProvider) ->
    flowFactoryProvider.defaults =
      target: '/uploadImages/'
      singleFile: true
      permanentErrors: [500, 501]
      maxChunkRetries: 1
      chunkRetryInterval: 5000
      simultaneousUploads: 1
    flowFactoryProvider.on('catchAll', (event) ->
      console.log(event)
      return
    )
    flowFactoryProvider.factory = fustyFlowFactory
    return
  ])
.config ($mdThemingProvider) ->
  $mdThemingProvider.theme('docs-dark', 'default').primaryPalette('light-blue').dark()
  return

app.controller('ParentController', ($scope)->
  $scope.messages = [];
  $scope.$on('from-iframe', (e, message) ->
    console.log('1');
    $scope.messages.push(message)
    return
  )

  $scope.message = () ->
    $scope.$broadcast('from-parent', 'Sent from parent')
    return
  return
)

# backend controllers
## user management ##
app.controller('userController', ($scope, $http, $interval, $mdDialog, $mdToast, $document) ->
  $scope.users = {}
  $scope.loading = false
  $scope.init = ->
    $scope.loading = true;
    $http.get('/api/users').
    success((data)->
      $scope.users = data.users
      $scope.roles =
        repeatSelect: null,
        availableOptions: data.roles
      $scope.loading = false
      return
    )
    return
  $scope.openAddUserDialog = (tip, user) ->
    category = category if category?
    $mdDialog.show({
      templateUrl: 'dialogadduser',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        roles: $scope.roles,
        user: user
      },
      controller: ['$scope', 'roles', 'user', ($scope, roles, user) ->
        $scope.showConfirmToast = (content) ->
          $mdToast.show(
            $mdToast.simple()
            .content(content)
            .position('top right')
            .hideDelay(3000)
          )
          return
        $scope.loading = false
        $scope.showActionToast = (data) ->
          $mdToast.show({
            controller: ['$scope', '$mdToast', ($scope, $mdToast) ->
              $scope.closeToast = ->
                $mdToast.hide()
                return
              $scope.errors = data
              return
            ],
            templateUrl: 'toastadduser',
            parent: $document[0].querySelector('#mdDialog'),
            hideDelay: 0,
            position: 'top right'
          })
        if tip is 1
          $scope.loading = true
          $scope.errors = null
          $http.get('api/users/' + user.id).
          success((data) ->
            $scope.user = data.users
            $scope.role_user = data.role;
            $scope.roles = {
              repeatSelect: null,
              availableOptions: data.roles
            };
            $('.password').hide()
            $('#addUser').hide()
            $('#editUser').show()
            $('#editUser').attr('data-id', user.id)
            $('#formTitle').html('Editare ' + $scope.user.first_name + ' ' + $scope.user.last_name)
            $scope.loading = false
            return
          )
        $scope.roles = roles
        $scope.cancel = ->
          $mdDialog.cancel()
          return
        $scope.addUser = ->
          $scope.loading = true
          $scope.errors = null
          $http.post('/api/users', {
            first_name: $scope.user.first_name,
            last_name: $scope.user.last_name,
            email: $scope.user.email,
            password: $scope.user.password,
            confirm_password: $scope.user.confirm_password,
            role: $scope.role,
            status: 1
          }).success(->
            $mdDialog.hide()
            $scope.showConfirmToast('Utilizatorul a fost aduagat cu success !')
            return
          ).error((data) ->
            $scope.loading = false
            $scope.showActionToast(data)
            return
          )
          return
        $scope.editUser = ->
          $scope.errors = null
          $scope.loading = true
          id = $('#editUser').attr('data-id')
          $http.put('/api/users/' + id, {
            id: id,
            first_name: $scope.user.first_name,
            last_name: $scope.user.last_name,
            email: $scope.user.email,
            role: $scope.role
          }).success(->
            $scope.showConfirmToast('Utilizatorul a fost editat cu success !')
            $mdDialog.hide()
            $scope.loading = false
            $scope.errors = null
            return
          ).error((data)->
            $scope.loading = false
            $scope.showActionToast(data)
            return
          )
      ]
    }).then(->
      $scope.init()
      return
    ,
      ->
        return
    )
    return
  $scope.status = (user, status)->
    $scope.loading = true
    $http.put('/api/enable_user/' + user.id, {
      status: status,
      id: user.id
    }).success((data) ->
      $scope.users = data.users
      $scope.roles = {
        repeatSelect: null,
        availableOptions: data.roles
      }
      $scope.loading = false
      return
    )

  $scope.init()
  $interval(->
    $scope.init()
    return
  , 60000)
  $scope.loadRoles = (role) ->
    $http.get('/api/roles').
    success((data) ->
      $scope.role = role
      $scope.roles = {
        repeatSelect: null,
        availableOptions: data.roles
      };
      $scope.loading = false
      return
    )
  return
)

## course category, course group, course ##
app.controller('courseDataController', ($scope, $http, $interval, $mdDialog, $mdToast, $document) ->
  $scope.listContent = {}
  $scope.parents = {}
  $scope.loading = false
  $scope.apiDestination = ''
  $scope.dataName = ''
  $scope.dialogTitle = ''
  $scope.editorOptions = {
    language: 'en',
    allowedContent: true,
    entities: false
  }
  $scope.init = (apiDestination, dataName)->
    apiDestination = if apiDestination then apiDestination else '/api/coursecategory'
    $scope.loading = true
    $scope.apiDestination = apiDestination
    $scope.dataName = dataName
    $http.get(apiDestination).
    success((data) ->
      $scope.listContent = data.list
      $scope.parents = {
        repeatSelect: null,
        availableOptions: data.parents
      };
      $scope.loading = false
      return
    )
    return

  $scope.openCourseDataDialog = (type, dataType, category) ->
    category = category if category?
    $mdDialog.show({
      templateUrl: 'dialogcoursedata',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true,
      locals: {category: category, dataType: dataType},
      controller: ['category', (category) ->
        $scope.showConfirmToast = (content) ->
          $mdToast.show(
            $mdToast.simple()
            .content(content)
            .position('top right')
            .hideDelay(3000)
          )
          return

        $scope.showActionToast = (data) ->
          $mdToast.show({
            controller: ['$scope', '$mdToast', ($scope, $mdToast) ->
              $scope.closeToast = ->
                $mdToast.hide()
                return
              $scope.errors = data
              return
            ]

            templateUrl: 'toastaddCategory',
            parent: $document[0].querySelector('#mdDialog'),
            hideDelay: 0,
            position: 'top right'
          })
          return

        $scope.obj = {};

        if type is 1
          $scope.loading = true
          $scope.errors = null
          switch dataType
            when 1
              $scope.hideSelect = true
              $scope.hidePaid = true
            when 2
              $scope.hideTag = true
              $scope.hidePaid = true
              $scope.hideBadge = true
              $scope.hideBackdrop = true
            when 3
              $scope.hidePaid = true
              $scope.hideTag = true

          $http.get($scope.apiDestination + '/' + category.id).
          success((data) ->
            $scope.category = data.item
            $('#addDataButton').hide()
            $('#editDataButton').show()
            $('#editDataButton').attr('data-id', category.id)
            $scope.dialogTitle = 'Editare ' + $scope.dataName + ' : ' + $scope.category.name
            $scope.loading = false
            return
          )
        else
          $scope.dialogTitle = 'Adauga ' + $scope.dataName + ''
          $scope.category = {}
          switch dataType
            when 1
              $scope.hideSelect = true
              $scope.hidePaid = true
            when 2
              $scope.hideTag = true
              $scope.hidePaid = true
              $scope.hideBackdrop = true
              $scope.hideBadge = true
            when 3
              $scope.hideTag = true

        $scope.cancel = ->
          $mdDialog.cancel()
          return

        $scope.addData = ->
          $scope.errors = null
          fd = new FormData()
          fd.append('id', null)
          fd.append('name', $scope.category.name)
          fd.append('tag', $scope.category.tag)
          fd.append('description', $scope.category.description)
          fd.append('backdrop_color', $scope.category.backdrop_color)
          fd.append('parent_id', $scope.category.parent_id)

          if $scope.category.paid
            fd.append('paid', $scope.category.paid)

          if $scope.obj.backdrop_image.files.length > 0
            fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file)

          if $scope.obj.badge_image.files.length > 0
            fd.append('badge_image_file', $scope.obj.badge_image.files[0].file)

          $http.post($scope.apiDestination, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          })
          .success(->
            $mdDialog.hide()
            return
          )
          .error((data) ->
            $scope.errors = data.errors
            $scope.loading = false
            return
          )
          return

        $scope.editData = ->
          console.log($scope.category)
          $scope.errors = null
          $scope.loading = true
          $scope.errors = null
          fd = new FormData()
          fd.append('id', $scope.category.id)
          fd.append('name', $scope.category.name)
          fd.append('tag', $scope.category.tag)
          fd.append('description', $scope.category.description)
          fd.append('backdrop_color', $scope.category.backdrop_color)
          fd.append('course_group_id', $scope.category.course_group_id)

          if $scope.category.paid
            fd.append('paid', $scope.category.paid ? 1: 0)

          if $scope.obj.backdrop_image.files.length > 0
            fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file)

          if $scope.obj.badge_image.files.length > 0
            fd.append('badge_image_file', $scope.obj.badge_image.files[0].file)

          $http.post($scope.apiDestination, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          }).success(->
            $mdDialog.hide()
            $scope.loading = false
            $scope.errors = null
            return
          ).error((data) ->
            $scope.errors = data
            $scope.loading = false
            return
          )
        return
      ]
    }).then(->
      $scope.init($scope.apiDestination, $scope.dataName)
      return
    )
    return
  $scope.init($scope.apiDestination, $scope.dataName)
  $interval(->
    $scope.init($scope.apiDestination, $scope.dataName)
    return
  , 60000)
  return
)

## lesson ##
app.controller('lessonController', ($scope, $http, $interval, $mdDialog, $mdToast, $document) ->
  $scope.lesson = {}
  $scope.lesson.dataType = 0
  $scope.editorOptions = {
    language: 'ro',
    allowedContent: true,
    entities: false
  }
  $scope.init = ->
    $http.get('/api/lessons').
    success((data) ->
      $scope.listContent = data.list
      $scope.parents = {
        repeatSelect: null,
        availableOptions: data.parents
      };
      $scope.loading = false
      return
    )
    return

  $scope.openLessonDialog = (type, lesson) ->
    if angular.isDefined(lesson)
      lesson = lesson

    console.log($scope.lesson)

    $mdDialog.show({
      templateUrl: 'dialogLesson',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true,
      locals: {lesson: lesson},
      controller: ['lesson', (lesson) ->
        $scope.showConfirmToast = (content) ->
          $mdToast.show(
            $mdToast.simple()
            .content(content)
            .position('top right')
            .hideDelay(3000)
          )
          return

        $scope.showActionToast = (data) ->
          $mdToast.show({
            controller: ['$scope', '$mdToast', ($scope, $mdToast) ->
              $scope.closeToast = ->
                $mdToast.hide()
                return
              $scope.errors = data
              return
            ]

            templateUrl: 'toastaddCategory',
            parent: $document[0].querySelector('#mdDialog'),
            hideDelay: 0,
            position: 'top right'
          })
          return

        $scope.obj = {};
        $scope.obj.video = null
        $scope.obj.badge_image = null
        $scope.obj.backdrop_image = null

        if type is 1
          $scope.loading = true
          $scope.errors = null
          $http.get('/api/lessons/' + lesson.id).
          success((data) ->
            $scope.lesson = data.item
            $('#addLessonButton').hide()
            $('#editLessonButton').show()
            $('#editLessonButton').attr('data-id', lesson.id)
            $('#formTitle').html('Editare lectie : ' + $scope.lesson.name)

            if($scope.lesson.video)
              $scope.lesson.dataType = 0
            if($scope.lesson.quiz)
              $scope.lesson.dataType = 1
            if($scope.lesson.compiler)
              $scope.lesson.dataType = 2

            if angular.isUndefinedOrNull($scope.lesson.compilerItems) || $scope.lesson.compilerItems is "" || typeof $scope.lesson.compilerItems != 'object'
              console.log($scope.lesson)
            else
              console.log($scope.lesson)
              $scope.lesson.compilerItems = JSON.parse($scope.lesson.compilerItems)
              console.log($scope.lesson)

            if angular.isUndefinedOrNull($scope.lesson.quizItems) || $scope.lesson.quizItems is "" || typeof $scope.lesson.quizItems != 'object'
              console.log($scope.lesson)
            else
              console.log($scope.lesson)
              $scope.lesson.quizItems = JSON.parse($scope.lesson.quizItems)
              console.log($scope.lesson)

            $scope.loading = false
            return
          )
        else
          $scope.lesson = null

        $scope.cancel = ->
          $mdDialog.cancel()
          return

        $scope.addCompilerItem = ->
          $scope.lesson.compilerItems[Object.keys($scope.lesson.compilerItems).length] = {
            'objective': '',
            'tag': '',
            'tag_content': '',
            'points_value': ''
          }
          return

        $scope.removeCompilerItem = (index) ->
          $scope.lesson.compilerItems.splice(index, 1)
          return

        $scope.addQuizItem = ->
          $scope.lesson.quizItems[Object.keys($scope.lesson.quizItems).length] = {
            'example': '',
            'question': '',
            'answer': '',
            'points_value': ''
          }
          return

        $scope.removeQuizItem = (index) ->
          $scope.lesson.quizItems.splice(index, 1)
          return

        $scope.addData = ->
          $scope.errors = null
          fd = new FormData()
          fd.append('_token', $('input[name="_token"]').val())
          fd.append('id', $scope.lesson.id)
          fd.append('name', $scope.lesson.name)
          fd.append('order', $scope.lesson.order)
          fd.append('description', $scope.lesson.description)
          fd.append('content', $scope.lesson.content)
          fd.append('backdrop_color', $scope.lesson.backdrop_color)
          fd.append('course_id', $scope.lesson.course_id)

          if $scope.lesson.dataType is "0" #video lesson
            if $scope.obj.video.files.length > 0
              fd.append('video', $scope.obj.video.files[0].file)

          if $scope.lesson.dataType is "1" #Quiz lesson
            fd.append('quiz', 1)
            fd.append('compiler', 0)
            fd.append('quizItems', JSON.stringify($scope.lesson.quizItems))

          if $scope.lesson.dataType is "2" #Compiler lesson
            fd.append('compiler', 1)
            fd.append('quiz', 0)
            fd.append('compilerItems', JSON.stringify($scope.lesson.compilerItems))

          if $scope.obj.suport_lectie.files.length > 0
            console.log($scope.obj.suport_lectie)
            fd.append('suport_lectie', $scope.obj.suport_lectie.files[0].file)

          if $scope.obj.backdrop_image.files.length > 0
            fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file)

          if $scope.obj.badge_image.files.length > 0
            fd.append('badge_image_file', $scope.obj.badge_image.files[0].file)
          xhr = new XMLHttpRequest
          xhr.upload.onprogress = (e) ->
            $scope.$apply ->
              percentCompleted = undefined
              if e.lengthComputable
                percentCompleted = Math.round(e.loaded / e.total * 100)
                if percentCompleted < 1
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = 'Uploading...'
                else if percentCompleted == 100
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = 'Saving...'
                else
                  $scope.progress = percentCompleted
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = percentCompleted + '%'
              return
            return

          xhr.upload.onload = (e) ->
            $mdDialog.hide()
            $scope.$apply ->
              if angular.isDefinedOrNotNull($scope.obj.video)
                $scope.obj.video.files[0].uploadStatus = 'Uploaded!'
              setTimeout (->
                $scope.$apply ->
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = ''
                  $scope.loading = false
                  return
                return
              ), 4000
              return
            return

          xhr.open 'POST', '/api/lessons'
          xhr.send fd
          return

        $scope.removeData = ->
          return


        $scope.editData = ->
          $scope.errors = null
          $scope.loading = true
          $scope.errors = null
          fd = new FormData()
          fd.append('_token', $('input[name="_token"]').val())
          fd.append('id', $scope.lesson.id)
          fd.append('name', $scope.lesson.name)
          fd.append('order', $scope.lesson.order)
          fd.append('description', $scope.lesson.description)
          fd.append('content', $scope.lesson.content)
          fd.append('backdrop_color', $scope.lesson.backdrop_color)
          fd.append('course_id', $scope.lesson.course_id)

          if $scope.lesson.dataType is "0"
            if $scope.obj.video != undefined
              if $scope.obj.video.files.length > 0
                fd.append('video', $scope.obj.video.files[0].file)

          if $scope.lesson.dataType is "1"
            fd.append('quiz', 1)
            fd.append('compiler', 0)
            fd.append('quizItems', JSON.stringify($scope.lesson.quizItems))

          if $scope.lesson.dataType is "2"
            fd.append('compiler', 1)
            fd.append('quiz', 0)
            fd.append('compilerItems', JSON.stringify($scope.lesson.compilerItems))

          if $scope.obj.suport_lectie.files.length > 0
            console.log($scope.obj.suport_lectie)
            fd.append('suport_lectie', $scope.obj.suport_lectie.files[0].file)

          if $scope.obj.backdrop_image.files.length > 0
            fd.append('backdrop_image_file', $scope.obj.backdrop_image.files[0].file)

          if $scope.obj.badge_image.files.length > 0
            fd.append('badge_image_file', $scope.obj.badge_image.files[0].file)

          xhr = new XMLHttpRequest

          xhr.upload.onprogress = (e) ->
            $scope.$apply ->
              percentCompleted = undefined
              if e.lengthComputable
                percentCompleted = Math.round(e.loaded / e.total * 100)
                if percentCompleted < 1
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = 'Uploading...'
                else if percentCompleted == 100
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = 'Saving...'
                else
                  $scope.progress = percentCompleted
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = percentCompleted + '%'
              return
            return

          xhr.upload.onload = (e) ->
            $mdDialog.hide()
            $scope.$apply ->
              if angular.isDefinedOrNotNull($scope.obj.video)
                $scope.obj.video.files[0].uploadStatus = 'Uploaded!'
              setTimeout (->
                $scope.$apply ->
                  if angular.isDefinedOrNotNull($scope.obj.video)
                    $scope.obj.video.files[0].uploadStatus = ''
                  $scope.loading = false
                  return
                return
              ), 4000
              return
            return

          xhr.open 'POST', '/api/lessons'
          xhr.send fd
        return
      ]
    }).then(->
      $scope.init()
      return
    )
    return

  $scope.init()
  $interval(->
    $scope.init()
    return
  , 60000)
  return
)

app.controller('homeController', ($scope, $sce, $http)->
  $scope.messages = [];
  $scope.objectives = "";
  $scope.url = $sce.trustAsResourceUrl('/iframe');
  $http.get('/html').success((response) ->
    $scope.objectives = response.objectives
    $scope.validator(response.html)
    return
  )
  $scope.$on('from-iframe', (e, message) ->
    key = message.key
    status = message.status
    $scope.objectives[key]['status'] = status
    return
  )
  $scope.validator = (data) ->
    $scope.$broadcast('validator', data)
    return
  $scope.message = (data) ->
    $scope.$broadcast('from-parent', data)
    return

  $scope.code = "ceva  altceva  list  item1";

  $scope.aceLoaded = (_editor)->
    _session = _editor.getSession()
    _renderer = _editor.renderer
    _editor.$blockScrolling = Infinity
    _editor.setReadOnly(false)
    _session.setUndoManager(new ace.UndoManager())
    _renderer.setShowGutter(true)
    _editor.on("changeSession", (obj) ->
      return
    )
    _session.on("change", () ->
      $scope.message(_editor.getSession().getValue())
      return
    )
    return

  return
)
## login/register/validation dialog management & form submission & logout ##
app.controller('userNavController', ($scope, $http, $interval, $mdDialog, $window)->
  $scope.loading = false
  $scope.user = {}
  $scope.recovery = false
  $scope.init = ->
    return
  $scope.init()
  $scope.isOpen = false;
  $scope.demo =
    isOpen: false,
    count: 0,
    selectedDirection: 'left'

  $scope.logout = ->
    window.location.replace('/logout')
    return

  $scope.loginDialogSubmit = ->
    $scope.loading = true
    $http.post('login', $scope.user)
    .success((data)->
      $scope.user = data.user
      if data.redirect
        window.location.replace(data.redirect)
      else if data.errors
        console.log(data.errors)
        $scope.errors = data.errors
        $scope.refillData = data.inputs
        $scope.loading = false
      return
    )
    return

  $scope.registerDialogSubmit = ->
    $scope.loading = true
    $http.post('register', $scope.user)
    .success((data)->
      console.log(data)
      if(data.success == true)
        $scope.errors = data.errors
        confirm = $mdDialog.confirm()
        .title('Contul a fost creeat cu succes')
        .content('Contul tau a fost creeat. Te rugam sa confirmi adresa de email pentru a te autentifica')
        .ariaLabel('Lucky day')
        .ok('Am confirmat')
        .cancel('Confirm mai tarziu');
        $mdDialog.cancel()
        $mdDialog.show(confirm).then(->
          $scope.openLoginDialog()
          return
          ->
            $mdDialog.cancel()
            return
        )
        $scope.notice = true
      else
        $scope.errors = data.errors
        $scope.loading = false
      return
    )
    return

  $scope.cancel = ->
    $mdDialog.cancel()
    return

  $scope.resendConfirmationMail = ->
    $scope.loading = true
    $http.post('retrimite_verificare', {'email': $scope.user.email})
    .success((data)->
      console.log(data)
      if(data.accexist == true)
        confirm = $mdDialog.confirm()
        .title('Mail confirmare cont')
        .content(data.message)
        .ariaLabel('Lucky day');
        if(data.delivery == true)
          confirm.ok('Confirm acum').cancel('Confirm mai tarziu');
        else
          confirm.ok('Autentificare').cancel('Inchide');
        $mdDialog.show(confirm).then(->
          $scope.loading = false
          $mdDialog.cancel()
          if(data.delivery == true)
            window.open(data.mailclient, '_blank')
          else
            $scope.openLoginDialog()
          return
          ->
            $mdDialog.cancel()
            if(data.delivery == false)
              $scope.openRegisterDialog()
            return
        )
        $scope.notice = true
      else
        confirm = $mdDialog.confirm()
        .title('Mail confirmare cont')
        .content(data.message)
        .ariaLabel('Lucky day')
        .ok('Creeaza cont')
        .cancel('Inchide');
        $mdDialog.show(confirm).then(->
          $scope.loading = false
          $mdDialog.cancel()
          $scope.openLoginDialog()
          return
          ->
            $mdDialog.cancel()
            return
        )
        $scope.notice = true

      return
    )
    return

  $scope.openRegisterDialog = ->
    $mdDialog.show({
      templateUrl: 'registerdialog',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true
    }).then(->
      $scope.init()
      return
    )
    return

  $scope.openRegisterDialog2 = ->
    $mdDialog.cancel()
    $scope.openRegisterDialog()
    return

  $scope.passwordRecovery = ->
    $scope.recovery = true
    return

  $scope.passwordRecoverySend = ->
    $http.post('reseteaza-parola', {email: $scope.user.email})
    .success((data)->
      console.log(data)
      if(data.accexist == true)
        confirm = $mdDialog.confirm()
        .title('Mail resetare parola')
        .content(data.message)
        .ariaLabel('Lucky day');
        if(data.delivery == true)
          confirm.ok('Reseteaza acum').cancel('Resetez mai tarziu');
        else
          confirm.ok('Reincearca').cancel('Inchide');
        $mdDialog.show(confirm).then(->
          $scope.loading = false
          $mdDialog.cancel()
          if(data.delivery == true)
            window.open(data.mailclient, '_blank')
          else
            $scope.openLoginDialog()
          return
          ->
            $mdDialog.cancel()
            if(data.delivery == false)
              $scope.openRegisterDialog()
            return
        )
        $scope.notice = true
      else
        confirm = $mdDialog.confirm()
        .title('Mail resetare parola')
        .content(data.message)
        .ariaLabel('Lucky day')
        .ok('Creeaza cont')
        .cancel('Inchide');
        $mdDialog.show(confirm).then(->
          $scope.loading = false
          $mdDialog.cancel()
          $scope.openLoginDialog()
          $scope.recovery = true
          return
          ->
            $mdDialog.cancel()
            return
        )
        $scope.notice = true
      return
    )
    return

  $scope.openLoginDialog = ->
    $scope.recovery = false
    $scope.loading = false
    $scope.errors = null
    $mdDialog.show({
      templateUrl: 'logindialog',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      scope: $scope,
      preserveScope: true
    }).then(->
      $scope.init()
      return
    )
    return

  return
)

## lesson sidenav open/close ##
app.controller('SidenavController', ($scope, $http, $mdSidenav) ->
  $scope.buildToggler = (navID) ->
    ->
      $mdSidenav(navID).toggle()
      return
  $scope.toggleRight = $scope.buildToggler('left')
  $scope.close = ->
    $mdSidenav('left').close()
    return
  return
)

## requestAnswer/sendAnswer/submitFullQuiz##
app.controller('quizController', ($scope, $http) ->
  $scope.answers = {}
  $scope.canSendFullQuiz = 0

  $scope.showAnswer = (lessonId, questionId) ->
    $http.post('/lessonQuizAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'getQA': true
    }).
    success((data) ->
      $scope.answers[questionId] = {'asked': true, 'value': data.answer, 'submitted': true}
      $("input[name='response_" + questionId + "']").parent().find("label").html('')
      $("input[name='response_" + questionId + "']").val(data.answer)
      $("input[name='response_" + questionId + "']").css('color', 'black')
      return
    )
    return

  $scope.sendAnswer = (lessonId, questionId) ->
    $http.post('/lessonQuizAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'userAnswer': $("input[name='response_" + questionId + "']").val()
    }).
    success((data) ->
      $("input[name='response_" + questionId + "']").css('border-bottom-color', 'rgba(0,0,0,0.12)')
      if angular.isDefined($scope.answers[questionId]['asked'])
        $scope.answers[questionId]['value'] = data.answer
        $scope.answers[questionId]['submitted'] = true
      else
        $scope.answers[questionId] = {'asked': false, 'value': data.answer, 'submitted': true}
      return
    )
    return

  $scope.sendFullQuiz = (lessonId, questionsCount) ->
    console.log(Object.keys($scope.answers).length)
    console.log($scope.answers)
    if Object.keys($scope.answers).length == 0
      $scope.canSendFullQuiz = 666
      $(".responseInput").css('border-bottom-color', 'red')

    angular.forEach($scope.answers, (v, k)->
      console.log(k)
      if v.value != null
        $scope.sendAnswer(lessonId, k)
      else
        $scope.canSendFullQuiz += 1
        $(".responseInput").css('border-bottom-color', 'red')
        $("input[name='response_" + k + "']").css('border-bottom-color', 'red')
      return
    )

    if Object.keys($scope.answers).length == questionsCount
      $scope.canSendFullQuiz = 0

    if $scope.canSendFullQuiz == 0
      console.log($scope.answers)
      console.log(JSON.stringify($scope.answers))
      $http.post('/lessonQuizValidation', {
        'lessonid': lessonId,
        'quizResults': JSON.stringify($scope.answers),
      }).
      success((data) ->
        if data.success is true
          console.log('sent and succesfull')
        else
          console.log('sent and failed')
        return
      )
      return
    else
      angular.forEach($scope.answers, (v, k)->
        if v.value is null
          $("input[name='response_" + k + "']").css('border-color', 'red')
        return
      )

  return
)

app.controller('compilerController', ($scope, $http) ->
  $scope.answerAsked = {}
  $scope.answers = {}

  $scope.requestAnswer = (lessonId, questionId) ->
    $http.post('/compilerAnswer', {'lessonid': lessonId, 'questionid': questionId, 'getQA': true}).
    success((data) ->
      $scope.answerAsked[questionId] = true
      $scope.answers[questionId] = {'asked': true, 'value': data.answer, 'submitted': true}
      $("input[name='response_" + questionId + "']").parent().find("label").html('')
      $("input[name='response_" + questionId + "']").val(data.answer)
      $("input[name='response_" + questionId + "']").css('color', 'black')
      return
    )
    return

  $scope.sendAnswer = (lessonId, questionId) ->
    $http.post('/compilerAnswer', {
      'lessonid': lessonId,
      'questionid': questionId,
      'userAnswer': $("input[name='response_" + questionId + "']").val()
    }).
    success((data) ->
      if angular.isDefined($scope.answers[questionId]['asked'])
        $scope.answers[questionId]['value'] = data.answer
        $scope.answers[questionId]['submitted'] = true
      else
        $scope.answers[questionId] = {'asked': false, 'value': data.answer, 'submitted': true}
      return
    )
  return
)

app.controller('userPassReset', ($scope, $http) ->
  $scope.userFields = {}
  $scope.responseMsg = null
  $scope.updatePass = ->
    $http.post('parola-noua', $scope.userFields)
    .success((data)->
      $scope.responseMsg = data.message
    )
    return

  $scope.updateRequest = ->
    $http.post('reseteaza-parola', {email: $scope.userFields.email})
    .success((data)->
      $scope.responseMsg = data.message
    )
    return

  return
)

app.controller('userSettings', ($scope, $http)->
  $scope.userData = {}
  $scope.message = null
  $scope.status = null
  $scope.saveUserdata = ->
    $http.post('updateData', $scope.userData)
    .success((data)->
      $scope.message = data.msg
      $scope.status = data.status
      return
    )
    return
  return
)